// 3rd-party Plugins
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~react-checkbox-tree/lib/react-checkbox-tree.css';

// Theme Styles
@import '../../_metronic/_assets/sass/style.react.scss';

// Default Layout themes
// @import "@metronic/_assets/sass/themes/layout/header/base/light.scss";
// @import "@metronic/_assets/sass/themes/layout/header/menu/light.scss";
// @import "@metronic/_assets/sass/themes/layout/brand/dark.scss";
// @import "@metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
@import '../../_metronic/_assets/sass/themes/layout/header/base/light.scss';
// Dark
// @import "../../_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
@import '../../_metronic/_assets/sass/themes/layout/header/menu/light.scss';
// Dark
// @import "../../_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
// Light
.brand-light {
  @import '../../_metronic/_assets/sass/themes/layout/brand/light.scss';
}
// .brand-dark {
//   @import "../../_metronic/_assets/sass/themes/layout/brand/dark.scss";
// }

// Aside themes
// Light
// @import "../../_metronic/_assets/sass/themes/layout/aside/light.scss";
// Dark
@import '../../_metronic/_assets/sass/themes/layout/aside/dark.scss';

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.card.card-custom {
  border-radius: 0;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.12);
}
.offcanvas-overlay {
  background: rgba(0, 0, 0, 0.5);
}
.h-min {
  height: min-content;
}
:focus {
  outline: none;
}
.disabled {
  opacity: 0.5;
}

.fixed-cell {
  position: -webkit-sticky;
  position: sticky;
  background: #1e1e2d;
  color: wheat !important;
  left: 0;
  z-index: 1;
  white-space: nowrap;
}

.editor-class {
  height: 200px !important;
  border: 1px solid #f1f1f1;
  padding: 0 16px;
}
.short-editor-class {
  height: 80px !important;
  border: 1px solid #f1f1f1;
  padding: 0 16px;
}
.toolbar-class {
  margin-bottom: 0px !important;
}
.bg-none {
  background: none;
}

.bg-green-light {
  background-color: #3dff45 !important;
}

.w-30 {
  width: 32% !important;
}

.text-lg {
  font-size: large !important;
}

.cursor-no-drop {

  cursor: no-drop !important;
}

.cursor-pointer {

  cursor: pointer !important;
}