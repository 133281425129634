.tw-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)))
}

.tw-bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity))
}

.tw-border-black {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity))
}

.tw-border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity))
}

.tw-rounded-full {
  border-radius: 9999px
}

.tw-border-solid {
  border-style: solid
}

.tw-border-2 {
  border-width: 2px
}

.tw-font-bold {
  font-weight: 700
}

.tw-h-0 {
  height: 0px
}

.tw-h-auto {
  height: auto
}

.tw-text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

* {
  --tw-shadow: 0 0 #0000
}

* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000
}

.tw-w-52 {
  width: 13rem
}

@keyframes tw-spin {
  to {
    transform: rotate(360deg)
  }
}

@keyframes tw-ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@keyframes tw-pulse {
  50% {
    opacity: .5
  }
}

@keyframes tw-bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@media (max-width: 11199px) {
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
}

@media (max-width: 575px) {
  .sm\:tw-mt-5 {
    margin-top: 1.25rem
  }
}

@media print {
}
